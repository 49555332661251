/* submit icon css */
.circle__checkmark {
  position: absolute;
  left: 65px;
  bottom: 100px;
  width: 0;
  height: 20px;
  background-color: teal;
  border-radius: 10px;
  transform: rotateZ(45deg);
  transform-origin: top left;
  animation: checkmarkSmall 0.1s linear 0.5s forwards;
}
.circle__checkmark:after {
  content: "";
  position: absolute;
  left: 33px;
  bottom: -20px;
  width: 0;
  height: 20px;
  background-color: teal;
  border-radius: 10px;
  transform: rotateZ(-100deg);
  transform-origin: top left;
  animation: checkmarkBig 0.2s linear 0.6s forwards;
}
.circle__box {
  width: 200px;
  height: 200px;
  margin: 50px auto;
  position: relative;
}
.circle__wrapper {
  width: 100px;
  height: 200px;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.circle__wrapper--right {
  right: 19px;
}
.circle__wrapper--left {
  left: 19px;
}
.circle__whole {
  width: 160px;
  height: 160px;
  border: 20px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  transform: rotate(-135deg);
}
.circle__right {
  border-top: 20px solid teal;
  border-right: 20px solid teal;
  right: 1px;
  animation: circleRight 0.5s linear forwards;
}
.circle__left {
  border-bottom: 20px solid teal;
  border-left: 20px solid teal;
  left: 1px;
  animation: circleLeft 0.5s linear forwards;
}
@keyframes circleRight {
  0% {
    transform: rotate(-135deg);
  }
  50%, 100% {
    transform: rotate(45deg);
  }
}
@keyframes circleLeft {
  0%, 50% {
    transform: rotate(-135deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}
@keyframes checkmarkSmall {
  0% {
    width: 0;
  }
  100% {
    width: 50px;
  }
}
@keyframes checkmarkBig {
  0% {
    width: 0;
  }
  100% {
    width: 90px;
  }
}

.delete_circle__checkmark {
  background-color: #ff3232;
}

.delete_circle__checkmark:after {
  background-color: #ff3232;
}

.delete_circle__right {
  border-top: 20px solid #ff3232;
  border-right: 20px solid #ff3232;
}

.delete_circle__left {
  border-bottom: 20px solid #ff3232;
  border-left: 20px solid #ff3232;
}