.srkdream{
    color:red;
}
/*.navbar-vertical.navbar-expand-xs{*/
/*    position:relative;*/
/*}*/
.srkpadding{
    padding-left: 20rem;
}

.icon-lg{
    height: 3.6rem;
    width: 3.6rem;
}
.google_map{
        position: relative;
    min-height: 360px;
    margin-top: 10px;
}