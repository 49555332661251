body {
    background: rgb(240, 242, 245);
}


::-webkit-scrollbar {
    display: none;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #C2C2C2 !important;
    opacity: 1;
    /* Firefox */
}

/*--------------------------------------------SIDE NAV-----------------------------------------*/
.navbar-vertical.navbar-expand-xs{
    width: 16.2% !important;
    max-width: initial !important;
}

.navbar .navbar-brand {
    font-size: 14px !important;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link{
    margin: 0 10px !important;
}
.pname{
    padding: 0 !important;
}
.sidenav .navbar-brand {
    padding: 1.5rem 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.css-wjh20t-MuiPagination-ul{
    padding: 12px !important;
    display: flex !important;
    justify-content: flex-end;
    flex-wrap:nowrap!important;
    overflow: scroll!important;
}

.page-not-found {
    background: #ffffff !important;
}

.form-control{
    text-transform: uppercase;
}

.lower-input {
    text-transform: none;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault.css-i4bv87-MuiSvgIcon-root {
    color: red;
}

.listening {
  position: relative;
  /*margin-top: 50px;*/
  margin-bottom: 15px;
}
.listening1 {
  display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #9b59b6;
  position: absolute;
  animation: audio-wave 1.5s infinite ease-in-out;
}

.listening2 {
left: 11px;
     display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #9b59b6;
  position: absolute;
  animation: audio-wave 1.5s infinite ease-in-out;
animation-delay: 0.2s;
}

.listening3 {
left: 22px;
     display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #9b59b6;
  position: absolute;
  animation: audio-wave 1.5s infinite ease-in-out;
animation-delay: 0.4s;
}

.listening4 {
left: 33px;
     display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #9b59b6;
  position: absolute;
  animation: audio-wave 1.5s infinite ease-in-out;
animation-delay: 0.6s;
}

.listening5 {
left: 44px;
     display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #9b59b6;
  position: absolute;
  animation: audio-wave 1.5s infinite ease-in-out;
animation-delay: 0.8s;
}

@keyframes audio-wave {
    0% {height:5px;transform:translateY(0px);background:#9b59b6;}
    25% {height:40px;transform:translateY(20px);background:#3498db;}
/*effect is to animate the height of each span from 5px to 30px*/
/*translateY makes Y axis move down to give the effect that it is growing from the center*/

    50% {height:5px;transform:translateY(0px);background:#9b59b6;}
    100% {height:5px;transform:translateY(0px);background:#9b59b6;}
}




/* Page not found */

/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
    margin-right: -50px;
    margin-left: 50px;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{

 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }


 .four_zero_four_bg h1{
 font-size:80px;
 }

  .four_zero_four_bg h3{
     font-size:80px;
     }

 .link_404{
	color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
.contant_box_404{ margin-top:-50px;}


/* Mic */
.micBtn {
	 border: none;
	 padding: 0;
	 border-radius: 100%;
	 width: 80px;
	 height: 80px;
	 font-size: 3em;
	 color: #fff;
	 padding: 0;
	 margin: 0;
	 background: red;
	 position: relative;
	 z-index: 999;
	 display: inline-block;
	 line-height: 100px;
	 text-align: center;
	 white-space: nowrap;
	 vertical-align: middle;
	 -ms-touch-action: manipulation;
	 touch-action: manipulation;
	 cursor: pointer;
	 -webkit-user-select: none;
	 -moz-user-select: none;
	 -ms-user-select: none;
	 user-select: none;
	 background-image: none;
}
 .pulse-ring {

	width: 80px;
    height: 80px;
    background: #189bff;
    border: 5px solid #189bff;
    border-radius: 50%;
    position: absolute;
    top: 25px;
    left: 25px;
    animation: pulsate infinite 1.5s;
}

 .kv-fill{
     padding: 10px;
     display: flex;
     border-radius: 5px;
     cursor: pointer;
 }
 .kv-fill:hover{
     background: #0096fb66;

 }
 .kv-full{
     background: rgba(0, 150, 251, 0.66);

 }
 .kv-fill:hover .kv-fill h6{
     color: #fff;
 }
@keyframes pulsate {
	 0% {
		 /*-webkit-transform: scale(1, 1);*/
         -webkit-transform: scale(1);
		 opacity: 1;
	}
	 100% {
		 /*-webkit-transform: scale(1.3, 1.3);*/
		 opacity: 0;
          -webkit-transform: scale(1.5);
	}
}

 .m-left {
	 margin-left: 300px;
}
 .type2 {
	 background: #189bff;
}
 .type2 .pulse-ring {
	 background: transparent;
}

.audioPlayer {
    display: flex !important;
}
.card_box{
    margin-bottom: 50px;
}
.quest:before{
    position: absolute;
    content: '';
    width: 90%;
    height: 2px;
    background: #c1c1c1;
    top: 0px;
    box-shadow: 0 2px 7px #5a5a5a;
}


.icon-bg {
    padding: 20px;
    border-radius: 50%;
    font-size: 30px;
    width: 70px;
    text-align: center;
    background-color: red;
    vertical-align: center;
}

.btn-box .btn {
    padding: 5px 24px;
    border-radius: 0;
    /*border: 1px solid #333;*/
    min-width: 100px;
    color: #fff;
}

.quest-button .btn-box .btn {
    border: 1px solid #333;

}

.btn-color{
    color: black !important;
}

.btn-box {
    display: flex;
    justify-content: space-around;
}
/*-------------_ARROW--------------------*/
.arrow-up {
      width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -8px;
    right: 20%;
}

.team-card-mv .card-img-top {
    height: 150px;
}

.card-size{
    min-height: 165px;
}


.kv-search {
    width: 100%;
    position: relative;
    display: flex;
  }

  .kv-searchTerm {
    width: 100%;
    border: 1px solid hsl(0, 0%, 80%);
    border-right: none;
    padding: 5px;
    height: 20px;
    border-radius: 5px 0 0 5px;
    outline: none;
    /* color: #9DBFAF; */
  }

  .kv-searchTerm:focus{
    color: #555;
  }

  .kv-searchButton {
    height: 38px;
    border: none;

    border: 1px solid hsl(0, 0%, 80%) !important;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
    border-left: 0;
    background-color: #fff;
    position: relative;
    border-left: 0px solid hsl(0, 0%, 80%) !important;
  }
  .kv-searchButton::after{
      position: absolute;
      content: '';
      width: 1px;
      height: 20px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: hsl(0, 0%, 80%);
  }


  /*Resize the wrap to see the search bar change!*/

  .kv-card{
      width: 99%;
      background-color: #ebebeb;
      margin-top: 10px;
      padding: 10px;
  }
  .kv-db,.kv-google{
      width: 100%;
  }
  .kv-search{
      display: flex;
  }

  .kv-hr{
    position: relative;
    text-align: center;
  }

  .kv-hr::after{
    content: '';
    position: absolute;
    height: 2px;
    width: 40%;
    background: hsl(0, 0%, 80%);
    top: 50%;
    transform: translatey(-50%);
    left: 0;
  }

  .kv-hr::before{
    content: '';
    position: absolute;
    height: 2px;
    width: 40%;
    background: hsl(0, 0%, 80%);
    top: 50%;
    transform: translatey(-50%);
    right: 0;
  }

  .kv-card{
    border: 1px solid #ddd;
    background: #fff;
    overflow: scroll;
    height: 300px;
  }
  .p-hover:hover{
    background: #3498db3d;
  }

  .kv-google p {
    font-size: 12px;
    padding: 5px 10px;
    color: #495057;
}

/*--------------------------------------GOOGLE MAP-------------------------------------------*/
@media (max-width: 1080px){
    .bname{
        font-size: 10px!important;
    }
}




@media (max-width: 992px) {
    .f-btn button{
        margin-top: 10px;
    }
    .google_map_input input{
        border-radius: 7px;
        padding: 12px !important;
        padding-left: 20px !important;
        border: 1px solid #C2C2C2 !important;
        font-size: 12px;
    }
    .google_map{
        min-height: 211px;
    }
    .gmnoprint{
        display: none;
    }

    .google_map_dropdown{
        overflow-x: scroll;
    }

    .multisteps-form__input{
        background-image: none !important;
        padding: 12px !important;
        padding-left: 20px !important;
        border-radius: 7px !important;

    }
    .dropdown-mv{
        background: #404643;
        border-radius: 7px;
    }
    .multisteps-form__input .form-control{
        border-radius: 7px!important;
    }
    .srkw-mv{
        padding: 12px;
        padding-left: 20px;
        color: #fff;
    }
    .css-e13as6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
    }
    .MuiFormControl-root .MuiTextField-root .css-e13as6-MuiFormControl-root-MuiTextField-root{
        width: 100%;
    }
    .css-1sohoek-MuiTimelineItem-root {
        justify-content: flex-end !important;
    }
    .form-check-inline{
        width: 100%;
    }

    body{
        margin-bottom: 100px!important;
    }
   /* .css-i4bv87-MuiSvgIcon-root{
        margin-top: -59px !important;
    } */
}



/*--------------------------------------GOOGLE MAP-------------------------------------------*/

@media (max-width: 576px) {
    .google_map_input input{
        border-radius: 7px;
        padding: 12px !important;
        padding-left: 20px !important;
        border: 1px solid #C2C2C2 !important;
        font-size: 12px;
    }
    .google_map{
        min-height: 211px;
    }
    .gmnoprint{
        display: none;
    }

    .google_map_dropdown{
        overflow-x: scroll;
    }

    .multisteps-form__input{
        background-image: none !important;
        padding: 12px !important;
        padding-left: 20px !important;
        border-radius: 7px !important;

    }
    .dropdown-mv{
        background: #404643;
        border-radius: 7px;
    }
    .multisteps-form__input .form-control{
        border-radius: 7px!important;
    }
    .srkw-mv{
        padding: 12px;
        padding-left: 20px;
        color: #fff;
    }
    .css-1sohoek-MuiTimelineItem-root {
        justify-content: flex-end !important;
    }
   /* .css-i4bv87-MuiSvgIcon-root{
        margin-top: -59px !important;
    } */
}

/*--------------------------------------------SIDE NAV-----------------------------------------*/
.navbar-vertical.navbar-expand-xs{
    width: 14% !important;
    top: 3px;
}

.navbar .navbar-brand {
    font-size: 21px !important;
}

.sidebar-image {
    width: 40px !important;
    height: 40px !important;
}

.sidebar-image-text {
    font-weight: 100;
    font-size: 14px;
    line-height: 0;
    letter-spacing: 0.00938em;
}

#sidenav-main {
    padding-left: 11px;
    padding-right: 11px;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link{
    margin: 0 10px !important;
}
.pname{
    padding: 0 !important;
}
.sidenav .navbar-brand {
    padding: 1.5rem 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}







.f-btn button {
    font-family: 'Roboto', sans-serif;
    color: #000;
    background-image: linear-gradient(195deg, rgb(235, 239, 244), rgb(206, 212, 218));
    border: none;
    border-radius: 0.5rem;
    padding: 10px 25px;
    font-size: .7rem;
    font-weight: 700;
    box-shadow: rgb(240 242 245 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(240 242 245 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(240 242 245 / 15%) 0rem 0.0625rem 0.3125rem 0rem;
}

.f-btn-display {
    display: none;
}





.nav-sticky{
    position: sticky !important;
    top: 0 !important;
    z-index: 9999;
        /*padding: 15px !important;*/
}
.navbar{
    padding: 15px;
}

/*---------SCROLLBAR HIDE-----------------*/
::-webkit-scrollbar {
    display: none;
}

/*-----------------NAVBAR------------*/
.navbar-vertical.navbar-expand-xs{
    max-width: 17% !important;
}




.navbarShadow{
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 16%) !important;
    background: #ffffffe3;
}


.active-micon{
    color: #fff;
}
.micon{
    color: #656565;
}

.tada-f .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
    margin: 2px 0;
}
.tada-img{
    position: relative;
    border: 1px solid #d0d0d0;
}
.tada-close{
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 10px;
}

@media(max-width: 992px){
    .kv-table th a {
    font-size: 12px;
}
     .kv-table th {
    padding-left: 10px !important;
}

    .kv-table h6 {
    font-size: 12px !important;
}
    .f-btn button {
    padding: 10px 15px;
}
    .card-kmv{
        margin-bottom: 125px;
    }
    .searchWrapper{
        overflow-x: scroll;
    }
}


/*#bigbutton {*/
/*    !*width: 180px;*!*/
/*    !*height: 120px;*!*/
/*    !*background-color: #cc0018;*!*/
/*    !*color: yellow;*!*/
/*    !*font-family: sans-serif;*!*/
/*    !*font-size: 16px;*!*/
/*    !*text-align: center;*!*/
/*    line-height: 100px;*/
/*    !*border-radius: 50%;*!*/
/*    !*margin: 30px;*!*/
/*    !*box-shadow: 0 20px #cb2033;*!*/

/*    border-radius: 50%;*/
/*    width: 200px;*/
/*    height: 200px;*/
/*    border: none;*/
/*    color: white;*/
/*    font-family: Avenir, Arial, sans-serif;*/
/*    font-weight: 900;*/
/*    font-size: 2.5rem;*/
/*    background: red;*/
/*    text-shadow: 0 3px 1px rgba(122,17,8,.8);*/
/*    box-shadow: 0 8px 0 rgb(183,9,0), 0 15px 20px rgba(0,0,0,.35);*/
/*    text-transform: uppercase;*/
/*    !*transition: .4s all ease-in;*!*/
/*    outline: none;*/
/*    cursor: pointer;*/
/*    text-align: center;*/
/*    !*-webkit-user-select: none;*!*/
/*    !*-webkit-tap-highlight-color: rgba(0,0,0,0);*!*/

/*}*/

#bigbutton:active {
    background-color: #cb2033;
    color: yellow;
    box-shadow: 0 10px #cc0018;
    transform: translateY(10px);
}

#bigbutton {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: none;
  color: white;
  font-family: Avenir, Arial, sans-serif;
  font-weight: 900;
  font-size: 32px;
  background: red;
  text-shadow: 0 3px 1px rgba(122,17,8,.8);
  box-shadow: 0 8px 0 rgb(183,9,0),
    0 15px 20px rgba(0,0,0,.35);
  text-transform: uppercase;
  transition: .3s all ease-in;
  outline: none;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.punch-btn {
    /*height: 50vh;*/
    display: flex;
    justify-content: center;
    align-items: center;
}



        /*body{*/
        /*    padding: 5px;*/
        /*}*/

        .rs-btn-box{
            background: #404643;
            border-radius: 25px 25px 0px 0px;
            position: fixed;
            bottom: 0px;
            left: 0;
            width: 99%;
            /* font-size: 40px; */
        }
        .rs-text, .rs-icon{
            color: #656565 !important;
        }
        .rs-icon{
            font-size: 18px;
        }
        .rs-text{
            font-size: 12px !important;
        }
        .rs-active{
            color: #fff !important;
        }
        .rs-icon-badge{
            background: #ededed;
            font-size: 20px;
            padding: 20px;
            border-radius: 50%;
            color: #404643;
        }
        .left{
            position: relative;;
        }
        .rs-pop{
            position: absolute;
            top: -45px;
            left: 50%;
            transform: translateX(-50%);
        }


@media (max-width: 992px){
    .css-1wnsr1i{
        width: 100% !important;
    }
    .modal-body h5{
        white-space: break-spaces;
    }
}


body{
            /*padding: 5px;*/
            /*background:#404643;*/
        }
        .menu-bar .head{
            border-bottom: 1px solid #404643;
            margin-bottom: 10px;
            font-weight: 700;
        }
        .menu-bar .rs-head{
            color: #404643;
        }
        .menu-bar .rs-btn-box{
            background: #fff;
            border-radius: 25px 25px 0 0;
            position: fixed;
            bottom: 0px;
            left: 0;
            width: 100%;
            /* font-size: 40px; */
        }
        .menu-bar .rs-text {
            color: #404643;
        }
        .menu-bar .rs-icon{
            font-size: 22px;
            color: #da5a92;
        }
        .menu-bar .rs-text{
            font-size: 12px !important;
        }
        .menu-bar .rs-active{
            /* color: #fff !important; */
        }
        .menu-bar .rs-icon-badge{
            background: #ededed;
            font-size: 20px;
            padding: 20px;
            border-radius: 50%;
            color: #404643;
        }

















/*body {*/
/*  background-color: #fff;*/
/*  font-family: arial, sans-serif;*/
/*  color: #394955;*/
/*}*/

/*h2 {*/
/*  margin-left: 20px;*/
/*}*/

/*.container {*/
/*  display: inline-block;*/
/*  margin: 30px 20px;*/
/*}*/
/*.container .label-text {*/
/*  display: block;*/
/*  text-align: center;*/
/*  font-size: 20px;*/
/*  font-weight: bold;*/
/*  margin-bottom: 15px;*/
/*}*/

.pie-wrapper {
  position: relative;
  width: 200px;
  height: 100px;
  overflow: hidden;
}
.pie-wrapper .arc, .pie-wrapper:before {
  content: '';
  width: 200px;
  height: 100px;
  position: absolute;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  left: 0;
  box-sizing: border-box;
}
.pie-wrapper:before {
  border: 50px solid #E8E8E8;
  border-bottom: none;
  top: 0;
  z-index: 1;
  border-radius: 300px 300px 0 0;
}
.pie-wrapper .arc {
  border: 50px solid #47CF73;
  border-top: none;
  border-radius: 0 0 300px 300px;
  top: 100%;
  z-index: 2;
}
.pie-wrapper .arc::before {
  content: "";
  height: 1px;
  width: 5px;
  position: absolute;
  top: 0px;
}
.pie-wrapper .score {
  color: #394955;
  font-size: 28px;
  display: block;
  width: 200px;
  text-align: center;
  margin-top: 64px;
}


.arc[data-value="72"] {
  -moz-animation: fill 2s;
  -webkit-animation: fill 2s;
  animation: fill 2s;
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
  transition: All 5s ease;
  border-color: #47CF73;
}

.arc[data-value="72"]:after {
  content: '';
  position: absolute;
  left: -40px;
  top: 5px;
}
.arc[data-value="72"]::before {
  background-color: #47CF73;
}

.arc[data-value="72"] {
  border-color: #FCD000;
}
.arc[data-value="72"]::before {
  background-color: #FCD000;
}

@-moz-keyframes fill {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
    border-color: #FF3C41;
  }
  50% {
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
    border-color: #47CF73;
  }
}

@-webkit-keyframes fill {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-color: #FF3C41;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border-color: #47CF73;
  }
}
@keyframes fill {
  0% {
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border-color: #FF3C41;
  }
  50% {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border-color: #47CF73;
  }
}

.homePageButtons .f-btn button{
    width: 25%;
    margin: 0px 2px!important;
    background-image: linear-gradient(195deg, rgb(239 239 239), rgb(181 184 187));
}
.homePageButtons .f-btn button:last-child{
    width: 10%!important;
}

.tour-plan-lg .css-i4bv87-MuiSvgIcon-root {
    border-radius: 7px;
    color: #040000!important;
    border: 4px solid #af7b7b!important;
    cursor: pointer !important;
    background: #af7b7b;
    margin-left: -55px !important;
    font-size: 41px;
    position: absolute;
    z-index: 300000000;
}

.srk-active{
    background-color: #202020;
    color: white;
}












.cssProgress {
    width: 100%;
    margin-bottom: 10px;
}

*, *::after, *::before {
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}


.cssProgress .progress1, .cssProgress .progress2, .cssProgress .progress3 {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-family: "Roboto", sans-serif;
}

.progress2 {
    background-color: #EEE;
    border-radius: 9px;
    box-shadow: inset 0px 1px 3px rgb(0 0 0 / 20%);
}

.progress2 .cssProgress-bar {
    height: 18px !important;
    border-radius: 9px;
}

.cssProgress .cssProgress-bar {
    display: block;
    float: left;
    width: 0%;
    height: 100%;
    background: #3798d9;
    box-shadow: inset 0px -1px 2px rgb(0 0 0 / 10%);
    transition: width 0.8s ease-in-out;
}

.cssProgress .progress1, .cssProgress .progress2, .cssProgress .progress3 {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-family: "Roboto", sans-serif;
}

.progress2 .cssProgress-label {
    line-height: 18px;
}
.cssProgress .cssProgress-label {
    position: absolute;
    overflow: hidden;
    left: 0px;
    right: 0px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.7em;
    text-align: center;
    text-shadow: 0px 1px rgb(0 0 0 / 30%);
}

.DashCardDark-attendance{
    max-width: 100% !important;
}

.pieTextLight-attendance{
    color: #e9e9e9 !important;
}

@media (min-width: 800px){
    .DashCardDark-attendance {
        padding-top: 31px !important;
        padding-bottom: 50px !important;
    }
}

@media (min-width: 1000px) and (max-width: 1575px){
    .chart-skills-attendance {
        position: relative !important;
        width: 190px !important;
        height: 95px !important;
    }
}












/*@media only screen and (min-width: 1761px) and (max-width: 2000px) {*/
/*    .bnr_middle img {height: 60vh;}.blue_box_div:before {bottom: 0%;}.blue_box {bottom: 10%;}*/
/*    .blue_box2 {left: 63%;}.blue_box_div2:before { top: 23%;}*/
/*}*/

/*:root {*/
/*     --primary: #DA4B26;*/
/*     --white: #ffffff;*/
/*     --black: #000000;*/
/*     --text:#333333;*/
/*     --gray: #A6A6A6;*/
/*     --font: "Outfit", sans-serif;*/
/*}*/
/* body {*/
/*     font-family: "Outfit", sans-serif;*/
/*     font-weight: 400;*/
/*    color: var(--text);*/
/*     overflow-x: hidden;*/
/*}*/
/* * {*/
/*     font-style: normal;*/
/*     margin: 0;*/
/*     padding: 0;*/
/*     box-sizing: border-box;*/
/*}*/
/* a {*/
/*    text-decoration: none;*/
/*}*/
/* ul {*/
/*     list-style: none;*/
/*     padding: 0;*/
/*}*/
/* .z-index{*/
/*    z-index: 99*/
/*}*/
/* .step-bar-wrapper{*/
/*     font-size:0;*/
/*     text-align:center;*/
/*     width:100%;*/
/*     position:relative;*/
/*     z-index:10;*/
/*}*/
/* a{*/
/*     color:#5c399e;*/
/*    !* change primary color *!*/
/*}*/
/* .step-wrapper{*/
/*     padding:0;*/
/*     margin:0;*/
/*     font-size:0;*/
/*     display:flex;*/
/*     align-items: center;*/
/*     justify-content: center;*/
/*     counter-reset: step;*/
/*     margin-top: 100px;*/
/*}*/
/* .step-wrapper li{*/
/*     width: 33%;*/
/*}*/
/* .step-wrapper li > a:before {*/
/*     content:'';*/
/*     width: 36px;*/
/*     height: 36px;*/
/*     display: block;*/
/*     font-size: 16px;*/
/*     font-weight:700;*/
/*     background-color: transparent;*/
/*     border-radius: 100%;*/
/*    !*z-index:1;*/
/*    *!*/
/*     position:absolute;*/
/*     text-align:center;*/
/*    top: 0;*/
/*}*/
/* .step-wrapper li > a:after {*/
/*     content: counter(step);*/
/*     counter-increment: step;*/
/*     width: 36px;*/
/*     height: 36px;*/
/*     line-height: 31px;*/
/*     font-size: 13px;*/
/*     font-weight: 700;*/
/*     border-radius: 100%;*/
/*    !*z-index: 1;*/
/*    *!*/
/*     position: absolute;*/
/*     text-align: center;*/
/*     border: #E6E6E6 2px solid;*/
/*     background: #EDF5F4;*/
/*     display: flex;*/
/*     align-items: center;*/
/*     justify-content: center;*/
/*     color: #000000;*/
/*    top: 0;*/
/*}*/
/* .step-wrapper li.completed > a:after {*/
/*    !*content:'\2713';*/
/*    *!*/
/*     color: #fff;*/
/*     background: #da4b26;*/
/*     background: #da4b26;*/
/*     border: #da4b26 2px solid;*/
/*}*/
/* .step-wrapper li:first-of-type a:before, .step-wrapper li:first-of-type a:after{*/
/*    !*margin-left:-21px;*/
/*    *!*/
/*}*/
/* .step-wrapper li:last-of-type > a:before, .step-wrapper li:last-of-type > a:after{*/
/*    !*margin-left:21px;*/
/*    *!*/
/*}*/
/* .step-wrapper li.completed > a:before{*/
/*     background: #fff;*/
/*     color:#c4c4c4;*/
/*     -webkit-box-shadow:0px 2px 4px 0px rgba(0,0,0,0.15);*/
/*     box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);*/
/*}*/
/* .step-wrapper li.active > a:before {*/
/*    !* border: #DA4B26 2px solid;*/
/*    background: #fff;*/
/*    *!*/
/*}*/
/* .step-wrapper li.active > a:after{*/
/*     color:#000000;*/
/*     border: #DA4B26 2px solid;*/
/*    background: #fff;*/
/*}*/
/* .step-wrapper li span{*/
/*     display: block;*/
/*     color: rgb(51 51 51 / 50%);*/
/*     width: 100%;*/
/*     text-align: center;*/
/*     margin-bottom: 0;*/
/*     position: absolute;*/
/*     top: 40px;*/
/*     font-size: 14px;*/
/*     font-weight: 500;*/
/*}*/
/* .step-wrapper li:not(.active):not(.completed) span a{*/
/*     color: #000000;*/
/*}*/
/* .step-wrapper li > a{*/
/*     position: relative;*/
/*     display: flex;*/
/*     justify-content: center;*/
/*     align-items: center;*/
/*    !*overflow:hidden;*/
/*    *!*/
/*     height:48px;*/
/*}*/
/* .step-wrapper li:first-of-type > a{*/
/*    !*padding-left: 40px;*/
/*    *!*/
/*}*/
/* .step-wrapper li:last-of-type > a{*/
/*    !*padding-right:40px;*/
/*    *!*/
/*}*/
/* .connecting-line {*/
/*     height: 2px;*/
/*     background: #E7E7E7;*/
/*     position: absolute;*/
/*     width: 60%;*/
/*     margin: 0 auto;*/
/*     left: 0;*/
/*     right: 0;*/
/*     top: 15px;*/
/*}*/
/* .header {*/
/*     background: var(--primary);*/
/*     color: var(--white);*/
/*     font-size: 24px;*/
/*     text-align: center;*/
/*     min-height: 68px;*/
/*     display: flex;*/
/*     align-items: center;*/
/*     justify-content: center;*/
/*     font-weight: 600;*/
/*     position: fixed;*/
/*     width: 100%;*/
/*     top: 0;*/
/*     left: 0;*/
/*    z-index: 9;*/

/*}*/
/*.header img {*/
/*     position: absolute;*/
/*     top: 29%;*/
/*     left: 15px;*/
/*     -webkit-transform: translate(0, 41%);*/
/*     transform: translate(0, 41%);*/
/*}*/
/* .step-wrapper li.disabled a span {*/
/*     color: rgb(51 51 51 / 50%);*/
/*}*/
/* .step-wrapper li.completed a span {*/
/*     color: #da4b26;*/
/*}*/
/* .step-wrapper li.active a span {*/
/*     color: #333333;*/
/*}*/
/*!*new*!*/
/* .wizard .nav-tabs {*/
/*     position: relative;*/
/*     margin-bottom: 0;*/
/*     border-bottom-color: transparent;*/
/*}*/
/* .wizard > div.wizard-inner {*/
/*     position: relative;*/
/*     text-align: center;*/
/*}*/
/*!*.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {*/
/*     color: #555555;*/
/*     cursor: default;*/
/*     border: 0;*/
/*     border-bottom-color: transparent;*/
/*}*/
/**!*/
/* .wizard li.active span.round-tab {*/
/*     background: #0db02b;*/
/*     color: #fff;*/
/*     border-color: #0db02b;*/
/*}*/
/* .wizard li.active span.round-tab i{*/
/*     color: #5bc0de;*/
/*}*/
/* .wizard .nav-tabs > li.active > a i{*/
/*     color: #0db02b;*/
/*}*/
/* .wizard li:after {*/
/*}*/
/* .wizard .nav-tabs > li a {*/

/*}*/

/* .wizard .tab-pane {*/
/*     position: relative;*/
/*     padding: 35px 0;*/
/*}*/
/* .wizard h3 {*/
/*     margin-top: 0;*/
/*}*/
/* .next-step {*/
/*     font-size: 18px;*/
/*     padding: 8px 24px;*/
/*     border: none;*/
/*     border-radius: 0;*/
/*     margin-top: 30px;*/
/*     width: 100%;*/
/*    color: #fff*/
/*}*/
/* .next-step {*/
/*     background-color: #DA4B26;*/
/*}*/
/* .skip-btn{*/
/*     background-color: #cec12d;*/
/*}*/
/* .step-head{*/
/*     font-size: 20px;*/
/*     text-align: center;*/
/*     font-weight: 500;*/
/*     margin-bottom: 20px;*/
/*}*/
/* .form-group label {*/
/*     display: block;*/
/*     font-size: 14px;*/
/*     margin: 0 0 5px;*/
/*     color: #333333;*/
/*}*/
/*.form-group {*/
/*     margin-bottom: 1rem;*/
/*}*/
/* .form-control {*/
/*    color: #000000;*/
/*    font-weight: 500;*/
/*     height: 47px;*/
/*     box-shadow: 0px -1px 0px 0px #484747 inset;*/
/*     background: #EDF5F4;*/
/*     border-radius: 0;*/
/*     border: none;*/
/*}*/
/* ::-webkit-input-placeholder {*/
/*     color: #000000;*/
/*    font-weight: 500;*/
/*}*/
/* :-ms-input-placeholder {*/
/*     color: #000000;*/
/*    font-weight: 500;*/
/*}*/
/* ::placeholder {*/
/*     color: #000000;*/
/*    font-weight: 500;*/
/*}*/

/*.form-control:focus {*/
/*    color: #212529;*/
/*    background-color: #fff0ec;*/
/*    border-color: transparent;*/
/*    outline: none;*/
/*    box-shadow: 0px -1px 0px 0px #000000 inset;*/
/*}*/








.map-activity-submit button:last-child{
    width: auto !important;
}

.trip-btn button:last-child {
    width: 55%!important;
}

.dash-att-btn button:last-child {
    width: 100%!important;
    height: 100px!important;
    margin: 0px!important;
}

.sv-f-btn button:last-child {
    width: auto!important;
}

.inv-f-btn button:last-child {
    width: auto!important;
}

.sv-button {
    background: green;
}

.datatable-mui-pagination {
    float: left;
}

.homepage-view {
    /* padding-left: 5px !important;
    padding-right: 33px !important; */
}
