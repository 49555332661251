#lppp{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.658);
    width: 100%;
    height: 100%;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-loader {
    width: 110px;
    height: 110px;
    border: 15px solid #f3f3f3;
    border-top: 15px solid #D81B60;
    border-radius: 50%;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }