
.myloader{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: conic-gradient(#3a86ff, transparent, transparent, transparent, transparent);
    position: relative;
    display: grid;
    place-items: center;
    animation: loader 0.7s linear infinite;
    left: 47%;
    top: 200%;
}

@media only screen and (max-width: 600px) {
    .myloader {
        left: 35%;
    }
  }

.myloader::before,
.myloader::after{
    content: '';
    position: absolute;
}
.myloader::before{
    width: 75%;
    height: 75%;
    border-radius: 50%;
    background: #f0f2f5;
}
.myloader::after{
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 13px;
    height: 13px;
    background: #3a86ff;
    border-radius: 50%;
}
@keyframes loader {
    0%{
        transform: rotate(0);
        background: conic-gradient(#3a86ff, transparent, transparent, transparent, transparent);
    }
    25%{
        background: conic-gradient(#ffba00, transparent, transparent, transparent);
    }
    50%{
        background: conic-gradient(#c9184a, transparent, transparent);
    }
    100%{
        transform: rotate(-360deg);
        background: conic-gradient(#480ca8, transparent, transparent);
    }
}