@media (max-width: 992px) {

    @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&family=Roboto&display=swap');

    * {
        padding: 0;
        margin: 0;
        white-space: nowrap;
    }

    html {
        scroll-behavior: smooth;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #C2C2C2 !important;
        opacity: 1;
        /* Firefox */
    }

    p {
        color: #000000 !important;
    }

    /* Common CSS  */
    /* .line-truncate-twoline{

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
} */
    body {
        background: #F0F2F5 !important;
        font-family: 'Roboto', sans-serif !important;
    }

    p {
        margin: 0;
        padding: 0;
    }

    .header-mv {
        background: #404643;
    }

    .profile-mv {
        border-radius: 50%;
        width: 36px;
    }

    .profile-mv img {
        width: 100%;
    }

    .form-control-mv {
        padding: 12px;
        font-size: 12px !important;
        border-radius: 6px !important;
        border: 1px solid #C2C2C2 !important;
        /*padding-left: 15px !important;*/
    }

    .header-mv .form-control-mv {
        border: 1px solid #656565 !important;
        padding: 0.375rem 2rem !important;
        color: #656565 !important;
        font-size: 12px !important;
    }



    .search-input-icon-mv {
        position: absolute;
        left: 12px;
        top: 18px;
        width: 14px;
        height: 14px;
    }

    .head-icon-mv {
        cursor: pointer;
    }

    .button-bar-mv {
        overflow-x: scroll;
    }

    .button-bar-mv::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }

    .button-bar-mv {
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
    }

    .btn-mv:focus {
        box-shadow: none !important;
    }

    .dashOption-mv .active {
        background: #404643 !important;

    }


    .active-micon {
        color: #fff;
    }
    .m-caption {
        color: #656565 !important;
        font-size: 10px;
    }
    .active-c {
        color: #fff !important;
    }

    .dashOption-mv button {
        background: #fff !important;
        color: #404643;
    }

    .dashOption-mv .btn {
        margin-right: 10px;
        overflow: initial;
        margin-bottom: 0;
        background: #fff;
        font-size: 10px;
    }

    .button-bar-mv img {
        padding-right: 5px;
    }

    .panel-mv {
        background: #fff;
        padding: 12px;
        margin-top: 20px;
        border-radius: 10px;
        /* min-height: 148px; */

    }

    .panel-mv p {
        word-wrap: break-word;
        overflow: hidden;
    }

    .panel-icon-mv {
        padding: 20px;
        border-radius: 6px;
    }

    .panel-mv span {
        color: #64A459;
    }

    .sales-box-mv {
        background: #fff;
        padding: 12px;
        border-radius: 10px;
    }

    .sale-mv img {
        background: #64A459;
    }

    .sales-mv table {
        text-align: center;
        margin-top: 10px;
    }

    .sales-table-mv {
        overflow-x: scroll;
        color: #333;
    }

    .sales-mv th {
        font-size: 12px;
        font-weight: 600;
        padding: 2px 10px;
    }

    .sales-mv td,
    .employee-mv td {
        font-size: 10px;
        padding: 2px 10px;
        color: #656565;
        vertical-align: middle;
        text-align: center;
    }

    .employee-mv {
        margin-bottom: 25px;
    }

    .employee-mv .head-content-mv {
        padding: 12px;
        background: #fff;
        border-radius: 10px;
    }

    .employee-mv select {
        border: 1px solid #C2C2C2;
        border-radius: 4px;
        padding: 10px;
        font-size: 14px;
        color: #000;
        font-weight: bold;
    }

    .styled-select-mv {
        -moz-appearance: none;
        /* Firefox */
        -webkit-appearance: none;
        /* Safari and Chrome */
        appearance: none;
    }

    .employee-mv select {
        width: auto;
    }

    .employee-mv h3 {
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-weight: 600;
    }

    .header-mv .form-control-mv {
        background: #404643 !important;
        border: 1px solid #656565 !important;
        padding: 0.375rem 2rem !important;
        color: #656565 !important;
        font-size: 12px !important;
    }

    .bottom-nav-mv {
        position: fixed;
        bottom: 0;
        width: 100vw;
        z-index: 999999;
    }

    .navbox-mv {
        background: #404643;
        padding: 20px;
        width: 100%;
        display: flex;
        border-radius: 20px;
        justify-content: space-between;
        align-items: center;

    }

    table {
        font-family: 'Poppins', sans-serif;
    }


    /*----------------------------------------TEAM-------------------------------------*/
    .team-card-mv {
        margin-bottom: 100px;
    }

    .team-card-mv h5 {
        font-weight: 600;
        font-size: 16px;
        margin: 15px 0 !important;
    }

    .team-card-mv .btn-mv {
        color: #fff;
        font-size: 12px;
        padding: 10px 15px;
        min-width: 86px;
    }

    .team-card-mv .card-img-top {
        border-radius: 4px !important;
        height: 150px;
    }

    .team-card-mv .card {
        border-radius: 10px !important;
    }

    .team-card-mv .btn {
        color: #fff;
        font-size: 12px;
        min-width: 86px;
        border: none !important;
        text-transform: none;
        font-weight: 400;
    }

    /*------------------------------------EMPLOYEE FORM-------------------------------------*/
    .emp-form-mv {
        margin-bottom: 250px;
    }

    .emp-form-mv form {
        border-radius: 10px;
    }

    .emp-form-mv form {
        background: #fff;
        padding: 15px 25px;
        font-family: 'Poppins', sans-serif;
        margin: 25px 0 10px 0;
    }

    .emp-form-mv label {
        font-size: 12px !important;
        font-weight: 500;
        color: #656565;
    }

    .emp-form-mv .form-control-mv {
        padding: 12px !important;
        border-radius: 7px;
    }

    .emp-form-mv select,
    .emp-form-mv .date {
        color: #C2C2C2;
    }

    .emp-form-mv .form-group {
        margin: 5px 0;
    }

    .lh-mv {
        line-height: 0;
    }

    .g-map-mv {
        width: 100%;
        height: 211px;
        overflow: hidden;
        border-radius: 4px;
    }

    /*--------------------------------SORT OPTION--------------------------------------*/
    .sort-mv label {
        font-size: 12px !important;
    }

    /*-------------------------SIDE MENU-----------------------------------*/
    .side-menu-mv {
        position: fixed;
        top: 225px;
    }

    .side-menu-mv img {
        width: 18px;
    }

    .side-menu-mv p {
        font-size: 7px;
        margin: 0;
        padding-top: 5px;
        color: #fff !important;
    }

    .menu-box-mv {
        background: #404643;
        width: 56px;
        border-radius: 0 50px 50px 0;
        padding: 10px;
        margin: -1px;
    }

    .form-check-input[type=radio] {
        width: 12px !important;
        height: 12px !important;
    }

    /*------------------------------------------------VISIT FORM ----------------------------------*/
    .visitForm-mv {
        margin-bottom: 10px !important;
    }

    .visit-body-mv {
        background: #fff !important;
    }

    .visit-dash-mv .active {
        background: #404643 !important;
    }

    .v-btn-mv .btn-mv {
        width: 100%;
        background: #404643;
        color: #fff;

    }

    .visit-dash-mv .btn-mv {
        background: #f4f4f4;
    }

    .visitForm-mv {
        background: #fff;
    }

    .visitForm-mv form {
        width: 90%;
        float: right;
    }

    .visitForm-mv select {
        font-size: 10px;
        height: 47px;
    }

    .form-check-input:checked {
        background-color: #F44335 !important;
        border-color: #F44335 !important;
    }

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        width: 100%;
    }

    .dataTable-top-mv>div:last-child {
        padding: 0 15px;
        width: 100%;
    }

    .dataTable-table th a {
        font-size: 12px;
        text-align: left !important;
    }
    .table thead th {
        font-size: 12px;
    }

    .dataTable-wrapper .dataTable-container .table tbody tr td {
        padding: 10px;
    }

    .f-card-mv {
        width: 100% !important;
    }

    .pro-mv p {
        font-size: 10px;
    }



    /*--------------------------------------------SIDE NAV-----------------------------------------*/
    .navbar-vertical.navbar-expand-xs{
        width: 16.66% !important;
    }




    /*------------------------------MOBILE VIEW CUSTOMER PAGE----------------------------------------*/
    #show-address-mv h5 {
        font-weight: bold;
        font-size: 16px !important;
    }

    .f-card-mv {
        width: 100% !important;
    }

    .input-group.input-group-dynamic .form-control,
    .input-group.input-group-dynamic .form-control:focus,
    .input-group.input-group-static .form-control,
    .input-group.input-group-static .form-control:focus {
        background-image: none;
        padding-left: 12px !important;
    }

    .multisteps-form__progress-btn.js-active:before {
        background-color: #64A459 !important;
    }

    .multisteps-form__progress-btn:after {
        background-color: #C2C2C2 !important;
    }

    .multisteps-form__progress-btn {
        text-indent: 0 !important;
    }

    .multisteps-form__progress-btn:before {
        background-color: #C2C2C2;
    }

    .multisteps-form__progress-btn {
        font-weight: 600 !important;
        color: #000 !important;
        font-size: 12px !important;
        font-family: 'Roboto', sans-serif !important;
    }
    .dropdown-toggle-mv{
        padding: 10px 2px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .css-b62m3t-container{
        width: 100%;
    }
    .searchWrapper {
        padding: 12px !important;
    }
    .card-header:first-child {
        border-radius: 0.75rem;
    }
    .card-mkv{
        margin-bottom: 150px;
    }
    .profile-mv{
        position: relative;
    }
    .log-option{
        position: absolute;
        width: 100%;
        background: #656561;
        z-index: 9999;
        top: 67px;
        left: 0;
        right: 0;
    }
    .log-option .nav-link{
        color: #fff;
        font-size: 12px;
    }

}

@media(max-width:576px){
    .f-kv{
        width: 100% !important;
    }
}

/*-------------------------------------------------------------------------------------*/
.dataTable-wrapper .dataTable-top {
    padding: 0;
}

* {
    /*margin: 0;*/
    /*padding: 0;*/
    /*box-sizing: border-box;*/
}

body {
    /* font-family: 'Montserrat', sans-serif; */
    /*background-color: #e5e5e5;*/
    /*color: #fff;*/
    /*padding: 10px;*/
}
.rstada-box{
    /* background: #fff; */
    border-radius: 25px !important;
    padding: 10px;
}

p{
    margin: 0;
    padding: 0  ;
}
h1 {
    text-align: center;
    margin: 2rem 0;
    font-size: 2.5rem;
}

.accordion {
    width: 90%;
    max-width: 100%;
    /* margin: 2rem auto; */
    margin-inline: auto;
    border-radius: 10px;
    padding: 0px;
    background: #fff;
    margin-bottom: 10px;
}

.accordion-item {
    background-color: #fff;
    color: #111;
    border: 0px;
}

.accordion-item-header {
    padding: 0.5rem 3rem 0.5rem 1rem;
    min-height: 3.5rem;
    line-height: 1.25rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.accordion-item-header::after {
    content: "\002B";
    font-size: 2rem;
    position: absolute;
    right: 1rem;
}

.accordion-item-header.active::after {
    content: "\2212";
}

.accordion-item-body {
    /*max-height: 0;*/
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.accordion-item-body-content {
    padding: 1rem;
    line-height: 1.5rem;
    border-top: 1px solid;
    border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
}


@media(max-width:767px) {
    html {
        font-size: 14px;
    }
}