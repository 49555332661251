.image-area {
  position: relative;
  width: 13%;
  background: #333;
}
.image-area img{
  max-width: 100%;
  height: auto;
}
.remove-image {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 10em;
    padding: 2px 6px 3px;
    text-decoration: none;
    font: 700 21px/20px sans-serif;
    background: #555;
    border: 3px solid #fff;
    color: #FFF;
    box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3);
      text-shadow: 0 1px 2px rgba(0,0,0,0.5);
      -webkit-transition: background 0.5s;
      transition: background 0.5s;
    }
.remove-image:hover {
 background: #E54E4E;
  padding: 3px 7px 5px;
  top: -11px;
right: -11px;
}
.remove-image:active {
 background: #E54E4E;
  top: -10px;
right: -11px;
}

.schedule-visit .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.s01 {
    width: 90%;
}

.inner-form {
    background: rgba(0,0,0,.5);
    padding: 25px 30px;
}

.schedule-visit-search-bar {
    display: flex;
}

.schedule-visit-search-bar .input-field.first-wrap {
    flex-grow: 1;
}

.schedule-visit-search-bar .input-field input {
    border-radius: 30px;
    height: 100%;
    background: #fff;
    border: 0;
    display: block;
    width: 100%;
    padding: 10px 32px;
    font-size: 20px;
}

.schedule-visit-search-bar .input-field.third-wrap {
    width: 150px;
    margin-right: 0;
}

.schedule-visit-search-bar .input-field {
    margin-right: 15px;
    height: 65px;
}

.schedule-visit-search-bar .input-field.third-wrap .btn-search {
    background-image: url("../../../src/custom/glitter.png");
    height: 100%;
    width: 100%;
    background-color: #63b641;
    white-space: nowrap;
    border-radius: 30px;
    font-size: 20px;
    color: #fff;
    transition: all .2s ease-out,color .2s ease-out;
    border: 0;
    cursor: pointer;
}


















.heading {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}
.main-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.searchInputWrapper {
  position: relative;
}

.searchInput {
  width: 20rem;
  height: 3rem;
  padding: 0 1rem;
  border-radius: 2rem;
  border: none;
  transition: transform 0.1s ease-in-out;
  box-shadow: rgb(0 0 0 / 35%) 0px 2px 11px;
}

::placeholder {
  color: #a1a1a1;
}

/* hide the placeholder text on focus */
:focus::placeholder {
  text-indent: -999px
}

.searchInput:focus {
  outline: none;
  transform: scale(1.1);
  transition: all 0.1s ease-in-out;
}

.searchInputIcon {
  position: absolute;
  right: 0.8rem;
  top: 0.9rem;
  color: #a1a1a1;
  transition: all 0.1s ease-in-out;
}

.main-search:focus-within >
.searchInputWrapper > .searchInputIcon {
  right: 0.2rem;
}